import api from './../services/api'
import {addUserSession, getUserSession} from './../services/auth'

import {
    setResearch,
    getResearch,
    isResearchPeding,
    removeResearch
} from './../services/pesquisa';

import {
    LISTA_SUPORTES_USUARIO,
    LISTA_AGENDAMENTOS_USUARIO,
    MODIFICA_USER_DATA,
    MODIFICA_CARRINHO_ITENS,
    MODIFICA_CARRINHO_VALOR,
    MODIFICA_CARRINHO_VALOR_FIXED,
    MODIFICA_CARRINHO,
    MODIFICA_NOTIFICACAO,
    MODIFICA_NOTIFICACAO_LIST,
    MODIFICA_CARRINHO_DESCONTO,
    MODIFICA_PESQUISA,
    MODIFICA_IMAGEM_LIST,
    SET_INTERECTIONS_LIST,
    SET_MOTIVO_CANCELAR_MATRICULA_LIST,
    SET_ENROLLMENT,
    SET_CERTIFICADOS,
    PENDING_FEE
} from '../actions/types'

export const suportesUsuarioFetch = () => dispatch =>
    api.get('/supports').then(
        suportes => {
            return dispatch({type: LISTA_SUPORTES_USUARIO, payload: suportes.data})
        }
    ).catch(
        error => {
            return error.message
        }
    )

export const loadUserById = (token) => dispatch =>
    api.post(`token`, {token}).then(
        res => {
            //sessionService.saveUser(res.data);
            addUserSession(res.data);
            return dispatch({
                type: MODIFICA_USER_DATA,
                payload: res.data,
            })
        }
    )

export const agendamentosUsuarioFetch = () => dispatch =>
    api.get('/aluno/agendamento').then(
        agendamentos => {
            return dispatch({type: LISTA_AGENDAMENTOS_USUARIO, payload: agendamentos.data})
        }
    ).catch(
        error => {
            return error.message
        }
    )

export const pesquisaUsuarioFetch = () => dispatch => {
    if (isResearchPeding()) {
        getResearch().then(
            research => {
                return dispatch({type: MODIFICA_PESQUISA, payload: JSON.parse(research)});
            }
        );
    } else {
        api.get('/student/research/list').then(
            research => {
                removeResearch().then(
                    () => {
                        if (research.data.research && research.data.research.id)
                            setResearch(JSON.stringify(research.data));
                        return dispatch({type: MODIFICA_PESQUISA, payload: research.data});
                    }
                )
            }
        );
    }
};

export const savePesquisa = (id, courseClassId, form) => dispatch =>
    api.post('/student/research/save-list', {id, courseClassId, form, version: '1.1'}).then(
        () => {
            return dispatch({type: MODIFICA_PESQUISA, payload: []})
        }
    );

export const modificaUserData = () => dispatch =>
    getUserSession().then(
        user => {
            if(user.carrinho && user.carrinho.length) {
                setCarrinho(dispatch, user.carrinho)
            }
            return dispatch({type: MODIFICA_USER_DATA, payload: user})
        }
    ).catch(error => dispatch({type: MODIFICA_USER_DATA, payload: []}))

const setCarrinho = (dispatch, carrinho) => {
    dispatch({type: MODIFICA_CARRINHO, payload: carrinho})
    dispatch({type: MODIFICA_CARRINHO_ITENS, payload: carrinho.length})
    let valor = 0
    carrinho.map((data) => {
            valor += parseFloat(data.priceValue)
            return valor
        }
    )

    let desconto = 0
    api.post('/student/discount-cart', {_cart: carrinho})
        .then(
            value => {
                desconto = parseFloat(value.data)
                dispatch({type: MODIFICA_CARRINHO_DESCONTO, payload: desconto})
                valor = parseFloat(valor - desconto)
                return dispatch({type: MODIFICA_CARRINHO_VALOR, payload: valor})
            }
        )
}

export const modificaCarrinhoCompra = (compraId, user) => dispatch =>
    api.post('student/buy-material/recover-purchase', {_id: compraId}).then(
        response => {
            const carrinho = response.data
            user['carrinho'] = carrinho
            //sessionService.saveUser(user)

            addUserSession(user);

            dispatch({type: MODIFICA_CARRINHO_ITENS, payload: carrinho.length})
            let valor = 0
            carrinho.map((data) => {
                valor += parseFloat(data.priceValue)
                return valor
            })
            return dispatch({type: MODIFICA_CARRINHO_VALOR, payload: valor})
        }
    )

export const modificaCarrinhoItens = (carrinho, user, quantityParcel = null) => {
    return dispatch => {
        user['carrinho'] = carrinho
        //sessionService.saveUser(user)
        addUserSession(user);

        dispatch({type: MODIFICA_CARRINHO_ITENS, payload: carrinho.length})
        let valor = 0
        carrinho.map((data) => {
            valor += parseFloat(data.priceValue)
            return valor
        });

        let desconto = 0
        api.post('/student/discount-cart', {_cart: carrinho})
            .then(
                value => {
                    desconto = parseFloat(value.data);
                    valor = valor - desconto;
                    const fixed = valor;
                    /*if(quantityParcel && quantityParcel >= 9) {
                        for (let i = 8; i <= quantityParcel; i++) {
                            valor += valor * 0.01;
                        }
                    }*/

                    dispatch({type: MODIFICA_CARRINHO_DESCONTO, payload: desconto});
                    dispatch({type: MODIFICA_CARRINHO_VALOR_FIXED, payload: fixed});
                    return dispatch({type: MODIFICA_CARRINHO_VALOR, payload: valor})
                }
            )
        //return dispatch({type: MODIFICA_CARRINHO_VALOR, payload: (valor - desconto)})
    }
}

export const carregarNotificacoes = (userId) => dispatch =>
    api.get('/my-notification/list')
        .then(
            response => {
                dispatch({type: MODIFICA_NOTIFICACAO_LIST, payload: response.data})
                dispatch({type: MODIFICA_NOTIFICACAO, payload: response.data.length})
            }
        )

export const carregarImagemPublicitaria = () => dispatch =>
    api.get('/student/advertising-campaign')
        .then(
            response => dispatch({type: MODIFICA_IMAGEM_LIST, payload: response.data})
        )


export const carregarInterectionList = () => dispatch =>
    api.get('/students/interections')
        .then(
            response => {
                return dispatch({type: SET_INTERECTIONS_LIST, payload: response.data})
            }
        );

export const motivosCancelarMatriculaList = () => dispatch =>
    api.get('/enrollment/reason-request-cancel-duo')
        .then(
            response => {
                return dispatch({type: SET_MOTIVO_CANCELAR_MATRICULA_LIST, payload: response.data})
            }
        );
export const solicitarCancelamentoMatricula = (id, form) => dispatch =>
    api.post('/enrollment/request-cancel-duo', {id, form})
        .then(
            () => {
                return dispatch({type: SET_MOTIVO_CANCELAR_MATRICULA_LIST, payload: []})
            }
        );

export const enrollmentInfoFetch = () => dispatch =>
    api.get('/student/my-info')
        .then(
            res => {
                return dispatch({type: SET_ENROLLMENT, payload: res.data})
            }
        );

export const setDataStudent = (id, data) => dispatch =>
    api.post(`/student/${id}/info`, {...data})
        .then(
            res => {
                //SET_DATA_STUDENT
                return res.data;
            }
        )

export const carregarCertificados = () => dispatch =>
    api.get('/student/my-report-cards')
        .then(
            res => dispatch({type: SET_CERTIFICADOS, payload: res.data})
        )

export const checkPendingFee = () => dispatch =>
    api.get('/enrollment-fee/pending')
        .then(
            res => dispatch({type: PENDING_FEE, payload: res.data})
        )