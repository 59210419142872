//Login
export const MODIFICA_USUARIO = 'modifica_usuario'
export const SET_DATA_STUDENT = 'set_data_student'
export const MODIFICA_SENHA = 'modifica_senha'
export const LOGIN_EM_ANDAMENTO = 'login_em_andamento'
export const MODIFICA_NOTIFICACAO = 'modifica_notificacao'
export const MODIFICA_NOTIFICACAO_LIST = 'modifica_notificacao_list'
export const LISTA_SUPORTES_USUARIO = 'lista_suportes_usuario'
export const MODIFICA_DIAS = 'modifica_dias'
export const MODIFICA_HORARIOS = 'modifica_horarios'
export const LOGIN_SUCCESS = 'login_success'
export const LOGIN_ERROR_MESSAGE = 'login_error_message'
export const MODIFICA_DIAS_HORARIOS_DISPONIVEIS = 'modifica_dias_horarios_disponiveis'
export const MODIFICA_PROCESSAMENTO = 'modifica_processamento'
export const LISTA_AGENDAMENTOS_USUARIO = 'lista_agendamentos_usuario'
export const MODIFICA_USER_DATA = 'modifica_user_data'
export const MODIFICA_LIVROS_A_COMPRAR = 'modifica_livros_a_comprar'
export const MODIFICA_COMPRAS = 'modifica_compras'
export const MODIFICA_CARRINHO = 'modifica_carrinho'
export const ADD_CARRINHO_SUCESSO = 'add_carrinho)sucesso'
export const LOGOUT_SUCCESS = 'logout_success'
export const MODIFICA_CARRINHO_ITENS = 'modifica_carrinho_itens'
export const MODIFICA_CARRINHO_VALOR = 'modifica_carrinho_valor'
export const MODIFICA_CARRINHO_VALOR_FIXED = 'modifica_carrinho_valor_fixed'
export const MODIFICA_CARRINHO_DESCONTO = 'modifica_carrinho_desconto'
export const MODIFICA_CARRINHO_REMOVER = 'modifica_carrinho_remover'
export const MODIFICA_CARRINHO_ADICIONAR = 'modifica_carrinho_adicionar'
export const MODIFICA_COMPRA_BOLETO = 'modifica_compra_boleto'
export const PAGAMENTO_CARTAO_SUCESSO = 'pagamento_cartao_sucesso'
export const MODIFICA_MINHAS_COMPRAS = 'modifica_minhas_compras'
export const MODIFICA_BOLETINS = 'modifica_boletins'
export const MODIFICA_FINANCEIRO_LIST = 'modifica_financeiro_list'
export const MODIFICA_SUPORTE_LIST = 'modifica_suporte_list'
export const SUPPORT_CADASTRO_SUCESSO = 'suporte_cadastro_sucesso'
export const RESETAR_DIAS_AGENDAMENTO = 'resetar_dias_agendamento'
export const MODIFICA_COMPOSITIONS = 'modifica_compositions'
export const MODIFICA_ATIVIDADE_COMPLEMENTAR = 'modifica_atividade_complementar'
export const MODIFICA_LIVROS_ATIVIDADE_COMPLEMNETAR = 'modifica_livros_atividade_complementar'
export const MODIFICA_CONTEUDO_ONLINE = 'modifica_conteudo_online'
export const MODIFICA_CONTEUDO_ONLINE_KIDS = 'modifica_conteudo_online_kids'
export const MODIFICA_LIVROS_CONTEUDO_ONLINE = 'modifica_livros_conteudo_online'
export const COMPOSITION_SAVE_PARCIAL = 'composition_save_parcial'
export const MODIFICA_PESQUISA = 'modifica_pesquisa'
export const MODIFICA_QUESTOES_PESQUISA = 'modifica_questoes_pesquisa'
export const MODIFICA_LIVROS_MATERIAL_APOIO = 'modifica_livros_material_apoio'
export const PESAQUISA_SALVA_SUCESSO = 'pesquisa_salva_com_successo'
export const MUSICS_CD = 'musics_cd'
export const RECOVER_PASSWORD = 'recover_password'
export const MODIFICA_IMAGEM_LIST = 'modifica_imagem_list';
export const ADD_HORARIO_DISPONIVEL = 'add_horario_disponivel';
export const SET_HORARIO_DISPONIVEL = 'set_horario_disponivel';
export const SET_INTERECTIONS_LIST = 'set_interections_list';
export const SET_MOTIVO_CANCELAR_MATRICULA_LIST = 'SET_MOTIVO_CANCELAR_MATRICULA_LIST';
export const SET_DEFAULT = 'SET_DEFAULT';
export const SET_ENROLLMENT = 'SET_ENROLLMENT';
export const SET_CERTIFICADOS = 'set_certificados';
//Renovação Matricula
export const SET_MATRICULA_RENOVACAO = 'set_matricula_renovacao';
export const SET_STEP_MATRICULA_RENOVACAO = 'set_step_matricula_renovacao';
export const SET_CONTRACT_MATRICULA_RENOVACAO = 'set_contrato_matricula_renovacao';
export const SET_STEP_MATRICULA_COMPLETO = 'set_step_matricula_completo';
export const SET_STEP_MATRICULA_ERRO = 'set_step_matricula_erro';
export const SET_STEP_ESCOLA = 'set_step_escola';
export const SET_ESCOLAS_FORM = 'set_escolas_form';
//Financeiro pendente
export const PENDING_FEE = 'pending_fee';