import React, {Component} from 'react'
import {connect} from 'react-redux'
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faBell,
    faGraduationCap,
    faHeadset,
    faPlayCircle,
} from '@fortawesome/free-solid-svg-icons'
//
import {
    suportesUsuarioFetch,
    agendamentosUsuarioFetch,
    pesquisaUsuarioFetch,
    carregarImagemPublicitaria,
    enrollmentInfoFetch,
    checkPendingFee
} from '../../actions/AppAction'
import Agendamento from './Agendamento'
import Suporte from './Suporte'
import Notificacao from "./Notificacao";
import Botoes from './Botoes'
import ModalPesquisa from './../../components/ModalPesquisa'
import ModalPublicidade from './../../components/ModalPublicidade';
import {confirmAlert} from "react-confirm-alert";

class Body extends Component {

    state = {
        loadingAgendamento: false,
        loadingSupport: false,
        modalPesquisa: true,
        enrollment: null,
    };

    componentWillMount() {
        this.props.checkPendingFee().then(
            res => {
                const data = res.payload.data
                if(data.pending) {
                    confirmAlert({
                        title: `Olá ${this.props.user.name.split(' ')[0]}`,
                        message: <div>
                                <h6 className="text-justify">Você possui pendências financeiras.</h6>
                                <br/>
                                <p>Sua matrícula <b>#{res.payload.data.enrollment}</b> não está regular.</p>
                            </div>,
                        buttons: [
                            {
                                label: 'Ciente',
                                onClick: () => {}
                            },
                        ]
                    });
                }
            }
        )

        this.props.enrollmentInfoFetch().then(
            () => this.props.suportesUsuarioFetch().then(
                () => this.props.agendamentosUsuarioFetch().then(
                    () => this.setState({loadingAgendamento: true})
                )
            )
        );

        this.props.pesquisaUsuarioFetch()

        this.props.carregarImagemPublicitaria();
    }

    render() {
        return (
            <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                <div className="row">
                    <div className="container">
                        <div className="card">

                            <div className="card-header">
                                <h1 className="jumbotron-heading mb-0" translate="no">Olá {this.props.user.name}!</h1>
                            </div>

                            <div className="card-body">
                                {
                                    (this.props.imagemPublicitaria && this.props.imagemPublicitaria.id) && (
                                        <ModalPublicidade imagemPublicitaria={this.props.imagemPublicitaria} />
                                    )
                                }
                                {
                                    this.props.notificacaoList && this.props.notificacaoList.length > 0 && (
                                        <div className="row mb-3">
                                            <div className="col">
                                                <div className="card shadow-sm">
                                                    <div className="card-header">
                                                        <h4 className="title-card-body card-title mb-0">
                                                            <FontAwesomeIcon icon={faBell} /> Notificações
                                                        </h4>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="container">
                                                            {
                                                                this.props.notificacaoList.map((item, key) => (
                                                                    <Notificacao notificacao={item} key={key}/>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    (this.props.user.type !== 'STUDENT_PRESENCIAL') ?
                                        (this.state.loadingAgendamento) ?
                                            <div className="row mb-3">
                                                <div className="col">
                                                    {this.props.enrollment && this.props.enrollment.enrollmentStatus !== 8 ? (
                                                        <div className="card shadow-sm">
                                                            <div className="card-header">
                                                                <h1 className="title-card-body card-title mb-0">
                                                                    <FontAwesomeIcon icon={faGraduationCap} /> Aulas
                                                                </h1>
                                                                <p className="card-subtitle mb-2 text-muted">Aulas
                                                                    agendadas
                                                                    para
                                                                    você.</p>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="container">
                                                                    {
                                                                        (this.props.agendamentos.length > 0) ?
                                                                            this.props.agendamentos.map((data) => (
                                                                                <Agendamento key={data.id}
                                                                                             agendamento={data}/>
                                                                            )) :
                                                                            <div className="alert alert-info">Você não
                                                                                possui
                                                                                agendamento</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : this.props.user.type === 'STUDENT_DUO' && (
                                                        <div className="card shadow-sm">
                                                            <div className="card-header">
                                                                <h1 className="card-title">Você ainda não realizou o
                                                                    nivelamento</h1>
                                                                <p className="card-subtitle mb-2 text-muted">Deseja
                                                                    realiza-lo agora?.</p>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="container">
                                                                    <a href={`https://matricula.rockfeller.com.br/nivelamento.php?email-aluno=${this.props.enrollment.email}&sala=AD0C67`}
                                                                       className="col-4 btn btn-success mr-2 mb-2">Sim</a>
                                                                    <a href={`https://joy.rockfellerbrasil.com.br/enrollment/nao-fazer-nivelamento/${this.props.enrollment.id}`}
                                                                       className="col4 btn btn-warning ml-2 mb-2">Não
                                                                        quero
                                                                        fazer nivelamento</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )

                                                    }
                                                </div>
                                            </div>
                                            : <div className="text-center"><Spinner/></div>
                                        : null
                                }
                                {
                                    (this.props.user.type === 'STUDENT_PRESENCIAL' || this.props.suportes.length > 0) ?
                                        (this.state.loadingAgendamento) ?
                                            <div className="row mb-3">
                                                <div className="col">

                                                    <div className="card shadow-sm">
                                                        <div className="card-header">
                                                            <h1 className="title-card-body card-title mb-0">
                                                                <FontAwesomeIcon icon={faHeadset} /> Suporte
                                                            </h1>
                                                            <p className="card-subtitle mb-2 text-muted">Suportes
                                                                agendados
                                                                para
                                                                você.</p>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="container">
                                                                {
                                                                    (this.props.suportes.length > 0) ?
                                                                        this.props.suportes.map((data) => (
                                                                            <Suporte key={data.id} suporte={data}/>
                                                                        )) :
                                                                        <div className="alert alert-info">Você não
                                                                            possui
                                                                            agendamento</div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : <div className="text-center"><Spinner/></div>
                                        : null
                                }
                                {
                                    (this.props.user.type === 'STUDENT_DUO') ?
                                        <div className="row mb-3">
                                            <div className="col">
                                                <div className="card shadow-sm">
                                                    <div className="card-header">
                                                        <h1 className="title-card-body card-title">
                                                            <FontAwesomeIcon icon={faPlayCircle} /> Video
                                                        </h1>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="container">
                                                            <iframe src={'https://player.vimeo.com/video/327813743'}
                                                                    width="100%"
                                                                    height="360"
                                                                    frameBorder="0"
                                                                    allowFullScreen/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null
                                }
                            </div>

                            <div className="card-footer pl-0 ml-1 pr-0 ml-1">
                                <Botoes user={this.props.user}/>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    (this.props.pesquisa.research) ? <ModalPesquisa pesquisa={this.props.pesquisa}/> : null
                }
                {/*
                    (this.props.user.methods.indexOf('ADULTS') >= 0) && <ModalVideo />
                */}
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        suportes: state.AppReducer.suportes,
        agendamentos: state.AppReducer.agendamentos,
        user: state.AppReducer.userData,
        pesquisa: state.AppReducer.pesquisa,
        imagemPublicitaria: state.AppReducer.imagemPublicitaria,
        enrollment: state.AppReducer.enrollment,
        notificacaoList: state.AppReducer.notificacaoList,
    }
)

export default connect(mapStateToProps, {
    suportesUsuarioFetch,
    agendamentosUsuarioFetch,
    pesquisaUsuarioFetch,
    carregarImagemPublicitaria,
    enrollmentInfoFetch,
    checkPendingFee
})(Body)
